import styled from 'styled-components';
import { useEffect } from 'react';
import { useStore } from 'effector-react';
import Scroll from 'react-scroll';
import { AboutScreen } from '../components/MainPage/AboutScreen';
import { AppointmentScreen } from '../components/MainPage/AppointmentScreen';
import { HomeScreen } from '../components/MainPage/HomeScreen';
import RewiewsScreen from '../components/MainPage/ReviewsScreen';
import { ServicesScreen } from '../components/MainPage/ServicesScreen';
import WhyWeScreen from '../components/MainPage/WhyWeScreen';
import CareScreen from '../components/MainPage/CareScreen';
import PricesScreen from '../components/MainPage/PricesScreen';
import UsInstagramScreen from '../components/MainPage/UsInstagramScreen';
import FooterScreen from '../components/MainPage/FooterScreen'
import { Menu } from '../components/Menu';
import { $appointment, dropAppointment } from '../entities';

const Wrapper = styled.div`
    min-height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
`;

export default function MainPage() {
    const appointment = useStore($appointment);

    useEffect(() => {
        if (!appointment) return;
        dropAppointment();
        Scroll.scroller.scrollTo('appointment', {
            smooth: true,
            delay: 200,
        });
    }, [appointment]);

    return (
        <Wrapper>
            <Menu />
            <HomeScreen />
            <ServicesScreen />
            <AboutScreen />
            <AppointmentScreen />
            <WhyWeScreen />
            <RewiewsScreen />
            <CareScreen />
            <PricesScreen />
            <UsInstagramScreen />
            <FooterScreen />
        </Wrapper>
    );
}