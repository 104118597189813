import { css } from 'styled-components';

export const desktop = (inner: any) => css`
  @media (min-width: ${1000 / 16}em) {
    ${inner};
  }
`;

export const mobile = (inner: any) => css`
  @media (min-width: ${650 / 16}em) {
    ${inner};
  }
`;