import styled from 'styled-components';
import SwiperCore, { Navigation } from 'swiper';
import { Element } from 'react-scroll';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import { ReactComponent as Arr } from '../../public/leftArr.svg';
import Slide from '../Slide';

import boteks from '../../public/services/boteks.jpg';
import bio from '../../public/services/bio.jpg';
import fotoomolozhenie from '../../public/services/foto.jpg';
import laser from '../../public/services/laser.jpg';
import lipolitik from '../../public/services/lipolitik.jpg';
import luxe from '../../public/services/luxe.jpg';
import ultraformer from '../../public/services/ultraformer.jpg';
import dnk from '../../public/services/gentest.jpg';
import lipInjections from '../../public/services/lip-injections.jpg';
import vector from '../../public/services/vector.jpg';
import popa from '../../public/services/popa.jpg';
import boteksWebp from '../../public/services/boteks.webp';
import bioWebp from '../../public/services/bio.webp';
import fotoomolozhenieWebp from '../../public/services/foto.jpg';
import laserWebp from '../../public/services/laser.webp';
import lipolitikWebp from '../../public/services/lipolitik.webp';
import luxeWebp from '../../public/services/luxe.webp';
import ultraformerWebp from '../../public/services/ultraformer.webp';
import dnkWebp from '../../public/services/gentest.webp';
import lipInjectionsWebp from '../../public/services/lip-injections.webp';
import vectorWebp from '../../public/services/vector.webp';
import popaWebp from '../../public/services/popa.webp';


SwiperCore.use([Navigation]);


const Wrapper = styled(Element)`
    padding: 20px 10px;
`;
const SwiperButton = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    flex-shrink: 0;
    &:hover {
        cursor: pointer;
        background-color: #ece5df;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        width: 16px;
        height: 29px;
    };
`;
const Head = styled.h1`
    color: #ccae9a;
`;
const Container = styled.div`
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div:first-child {
        svg {
            transform: rotate(180deg);
            margin: 0 6px 0 0;
        }
    }
    div:last-child {
        svg {
            margin: 0 -6px 0 0;
        }
    }
`;
const SwiperContainer = styled.div`
    width: calc(100vw - 110px);
    @media(min-width: 1250px) {
        width: 1100px;
    }
`;

export function ServicesScreen() {


    return (
        <Wrapper name="services">
            <Head>Услуги</Head>
            <Container>
                <SwiperButton className="swiper-button-prev">
                    <Arr />
                </SwiperButton>
                <SwiperContainer>
                    <Swiper
                        navigation={{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev'
                        }}
                        loop={true}
                        spaceBetween={20}
                        slidesPerView={(document.body.clientWidth > 1300 ? 3 : (document.body.clientWidth > 750 ? 2 : 1))}
                    >
                        <SwiperSlide>
                            <Slide text={'Ботулинотерапия'} foto={boteks} webp={boteksWebp} url="boteks" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <Slide text={'SMAS лифтинг ULTRAFORMER'} foto={ultraformer} webp={ultraformerWebp} url="ultraformer" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <Slide text={'Увеличение губ'} foto={lipInjections} webp={lipInjectionsWebp} url="lipInjections" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <Slide text={'Биоревитализация'} foto={bio} webp={bioWebp} url="bio" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <Slide text={'Фотоомоложение'} foto={fotoomolozhenie} webp={fotoomolozhenieWebp} url="foto" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <Slide text={'Лазерная эпиляция Soprano'} foto={laser} webp={laserWebp} url="laser" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <Slide text={'Липолитики'} foto={lipolitik} webp={lipolitikWebp} url="lipolitik" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <Slide text={'Тредлифтинг Luxe face & body'} foto={luxe} webp={luxeWebp} url="luxe" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <Slide text={'Генетический тест'} foto={dnk} webp={dnkWebp}  url="/dnk" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <Slide text={'Увеличение ягодиц'} foto={popa} webp={popaWebp} url="buttocks" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <Slide text={'Векторный лифтинг Radiesse'} foto={vector} webp={vectorWebp} url="vector" />
                        </SwiperSlide>
                    </Swiper>
                </SwiperContainer>
                <SwiperButton className="swiper-button-next">
                    <Arr />
                </SwiperButton>
            </Container>
        </Wrapper>
    );
}