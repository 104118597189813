import { Link } from 'react-router-dom';
import './slide.scss';

interface Props {
    text: string,
    foto: string,
    url?: string,
    webp: string
}

export default function Slide(props: Props) {

    return (
        <div className="slide__wrapper">
            <picture>
                <source srcSet={props.webp} type="image/webp" />
                <img className="slide__img" src={props.foto} alt="" />
            </picture>
            <div>{props.text}</div>
            {
                props.url &&
                <Link to={props.url}>
                    <button type="button">Подробнее</button>
                </Link>
            }
        </div>
    );
}