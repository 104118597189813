import { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom"
import { GlobalStyle } from './components/GlobalStyle';
import MainPage from './pages/MainPage';
//const MainPage = lazy(() => import('./pages/MainPage'));
const Bio = lazy(() => import('./pages/ServicesPages/Bio'));
const Boteks = lazy(() => import('./pages/ServicesPages/Boteks'));
const Ultraformer = lazy(() => import('./pages/ServicesPages/Ultraformer'));
const Foto = lazy(() => import('./pages/ServicesPages/Foto'));
const Luxe = lazy(() => import('./pages/ServicesPages/Luxe'));
const Lipolitik = lazy(() => import('./pages/ServicesPages/Lipolitik'));
const Laser = lazy(() => import('./pages/ServicesPages/Laser'));
const LipInjections = lazy(() => import('./pages/ServicesPages/LipInjections'));
const Buttocks = lazy(() => import('./pages/ServicesPages/Buttocks'));
const Dnk = lazy(() => import('./pages/ServicesPages/Dnk'));
const Vector = lazy(() => import('./pages/ServicesPages/Vector'));

function App() {
  return (
    <BrowserRouter>
      <GlobalStyle />
      <Suspense fallback={<div></div>}>
        <Switch>
          <Route component={MainPage} path="/" exact />
          <Route component={Bio} path="/bio" />
          <Route component={Boteks} path="/boteks" />
          <Route component={Ultraformer} path="/ultraformer" />
          <Route component={Foto} path="/foto" />
          <Route component={Luxe} path="/luxe" />
          <Route component={Lipolitik} path="/lipolitik" />
          <Route component={Laser} path="/laser" />
          <Route component={LipInjections} path="/lipInjections" />
          <Route component={Buttocks} path="/buttocks" />
          <Route component={Dnk} path="/dnk" />
          <Route component={Vector} path="/vector" />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;