import { Element } from 'react-scroll';
import styled, { css } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import { desktop, mobile } from "../../utils/media";
import dilya from '../../public/images/dilya1.jpg';
import diplom1 from '../../public/diploms/diplom1.jpg';
import diplom2 from '../../public/diploms/diplom2.jpg';
import diplom3 from '../../public/diploms/diplom3.jpg';
import diplom5 from '../../public/diploms/diplom5.jpg';
import diplom6 from '../../public/diploms/diplom6.jpg';
import diplom7 from '../../public/diploms/diplom7.jpg';
import license from '../../public/diploms/lisence.jpg';


SwiperCore.use([Autoplay]);

const Wrapper = styled(Element)`
    background: linear-gradient(180deg, rgb(239, 224, 205), rgb(255, 255, 255));
    padding: 2rem;
    @media(max-width: 750px) {
        padding: 10px;
    }
`;
const Container = styled.div`
    position: relative;
    max-width: 960px;
    margin: 0 auto;
`;
const Foto = styled.img`
    max-width: 50%;
    margin: 0 40px 0 0;
    object-fit: contain;
    @media(max-width: 750px) {
        max-width: none;
        width: 100%;
        height: calc(100vw / 0.666);
    }
`;
const About = styled.h1`
    width: 100%;
    text-align: left;
    color: #AA7A5A;
    position: absolute;
    left: 0;
    ${mobile(css`
        font-size: 9vw;
        text-align: center;
        padding: 0 0 0 1.3em;
    `)}
    ${desktop(css`
        padding: 0;
        text-align: right;
        font-size: 7vw;
    `)}
`;
const Card = styled.div`
    max-width: none;
    padding: 20px 0 0 0;
    width: 100%;
    letter-spacing: 1.2px;
    font-weight: 400;
    color: #AA7A5A;
    ${mobile(css`
        padding: 120px 0 0 0;
        font-size: 16px;
    `)}
    ${desktop(css`
        width: 377px;
        font-size: 18px;
        padding: 25vh 0 0 0;
    `)}
`;
const FlexContainer = styled.div`
    display: flex;
    margin: 0 0 1rem 0;
    @media(max-width: 750px) {
        flex-wrap: wrap;
    }
`;
const DiplomSlide = styled.div`
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 300px;
    text-align: center;
`;
const DiplomFoto = styled.img`
    width: 100%;
`;


export function AboutScreen() {


    return (
        <Wrapper name="about">
            <Container>
                <About>Обо мне</About>
                <FlexContainer>
                    <Foto src={dilya} />
                    <Card>
                        Сухова Дилана Анатольевна - пластический хирург, дерматовенеролог, косметолог с превентивным подходом.
                        Эксперт по антивозрастным методикам. Составляю персональные программы процедур и ухода для сохранения молодости и красоты.
                        Мой взгляд на эстетическую медицину -  это баланс и гармония между красотой и здоровьем.
                        Помогу вам сохранив индивидулальность и естественность, стать лучшей версией себя.
                    </Card>
                </FlexContainer>
                <Swiper
                    navigation={{
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }}
                    loop={true}
                    spaceBetween={20}
                    autoplay={{
                        delay: 2000
                    }}
                    slidesPerView={(document.body.clientWidth > 1300 ? 3 : (document.body.clientWidth > 750 ? 2 : 1))}
                >
                    <SwiperSlide>
                        <DiplomSlide>
                            <DiplomFoto src={diplom1} />
                            <p>Российский университет Дружбы Народов "Лечебное дело"</p>
                        </DiplomSlide>
                    </SwiperSlide>
                    <SwiperSlide>
                        <DiplomSlide>
                            <DiplomFoto src={diplom2} />
                            <p>Институт усовершенствования врачей "Пластическая хирургия"</p>
                        </DiplomSlide>
                    </SwiperSlide>
                    <SwiperSlide>
                        <DiplomSlide>
                            <DiplomFoto src={diplom3} />
                            <p>"Применение Ботулинотерапии в эстетической медицине"</p>
                        </DiplomSlide>
                    </SwiperSlide>
                    <SwiperSlide>
                        <DiplomSlide>
                            <DiplomFoto src={license} />
                            <p>
                                Лицензия на медицинскую деятельность выдана:
                                ООО "ЭстеМед"
                                ИНН 7707440055
                                ОГРН 1207700174549
                            </p>
                        </DiplomSlide>
                    </SwiperSlide>
                    <SwiperSlide>
                        <DiplomSlide>
                            <DiplomFoto src={diplom5} />
                            <p>Медицинский институт усовершенствования врачей "Дерматовенерология"</p>
                        </DiplomSlide>
                    </SwiperSlide>
                    <SwiperSlide>
                        <DiplomSlide>
                            <DiplomFoto src={diplom6} />
                            <p>Российский университет Дружбы Народов переподготовка "Косметология"</p>
                        </DiplomSlide>
                    </SwiperSlide>
                    <SwiperSlide>
                        <DiplomSlide>
                            <DiplomFoto src={diplom7} />
                            <p>"Применение микроимплантов в эстетической медицине"</p>
                        </DiplomSlide>
                    </SwiperSlide>
                </Swiper>
            </Container>
        </Wrapper>
    );
};